import React, { useEffect, useState } from "react";
import {
  Button, Box, VStack, Image, Flex, FormControl, FormErrorMessage, FormLabel,
  Stack, Input, InputGroup, InputRightElement, Text
} from "@chakra-ui/react";
import AssesshubLogo from '../img/assesshub_logo.png';
import LoginImage from '../img/login_background.jpg'
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import * as Yup from "yup";
import { loginUser, adminloginUser } from "../api/auth";
import { AuthContext } from "../store/authContext";
import { useNavigate } from "react-router-dom";
import { MdErrorOutline } from "react-icons/md";

const LoginInfoSchema = Yup.object().shape({
  email: Yup.string().max(100, "Too long!").email("Not a valid email!").required('Required!'),
  password: Yup.string().required('Required!'),
  redirect: Yup.boolean()
})


export const Login = () => {
  const { user, login, isAuthenticated } = React.useContext(AuthContext);
  const [show, setShow] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [userData, setUserData] = useState({ email: '', password: '' })
  const [errors, setErrors] = useState({ email: '', password: '' })
  const [disablePage, setDisablePage] = useState(false)
  const navigate = useNavigate();

  const handleShowClick = () => setShow(!show)

  const handleTokenLogin = (token: string, id: string, email: string, username: string, role: string) => {
    // store data into localstorage
    localStorage.setItem('token', JSON.stringify(token))
    localStorage.setItem('user', JSON.stringify({ id: id, email: email, username: username, role: role, redirect: true }))

    // navigate to dashboard
    navigate('/')
  }

  const handleSignIn = async (email: string, password: string, redirect: boolean) => {
    setDisablePage(true)
    let data = await loginUser({ email, password, redirect }).catch(error => {
      console.log(error)
    })
    let admin_data = await adminloginUser({ email, password, redirect }).catch(error => {
      console.log(error)
    })

    if (data) {
      if (data.success) {
        // store token and credentials into localstorage
        localStorage.setItem("token", JSON.stringify(data.token))
        localStorage.setItem("user", JSON.stringify(data.user))

        // set context data
        login(data.user)
        navigate('/')
      } else {
        setErrorMessage(data.error)
      }
    }
   

    setDisablePage(false)
  }
  const validateForm = async () => {
    try {
      await LoginInfoSchema.validate(userData, { abortEarly: false });
      // Validation succeeded
      setErrors({ email: "", password: '' });
      return true
    } catch (validationErrors) {
      // Validation failed
      const newErrors = { email: '', password: '' };
      validationErrors.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });

      setErrors(newErrors);
      return false
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    // validation
    const isValid = await validateForm()

    if (isValid) {
      handleSignIn(userData.email, userData.password, false);

    }
  }


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const adminLoginToken = params.get('token');
    const id = params.get('id')
    const email = params.get('email')
    const username = params.get('username')
    const role = params.get('role')

    if (adminLoginToken) {
      // function to  complete next login process
      handleTokenLogin(adminLoginToken, id, email, username, role)
    }
    console.log("[Auth] checking login status in login page");

    const token = localStorage.getItem('token')
    const userObject = localStorage.getItem("user")
    const userData = JSON.parse(userObject)

    if (userData && userData.email !== "") {
      login(userData)
    }

    if (token && isAuthenticated) {
      console.log(`[Auth] found already login user ${user}`);
    }
  },
    // [isAuthenticated, user]
    [isAuthenticated]
  );



  return (
    <>
      <Flex height="100vh" align="center" justify="center">
        <Box display={{ base: 'none', lg: 'block' }} width="75%" height="100vh">
          <Image src={LoginImage} alt="Background Image" objectFit="cover" height="100%" width="100%" />
        </Box>
        <VStack spacing={8} width={{ base: '100%', md: '50%' }} p={8}>
          <Box mb={8}>
            <Image src={AssesshubLogo} alt="Background Image" />
            <br />


            <form onSubmit={handleSubmit}>
              <Stack w="full" spacing={3}>
                <FormControl
                  isInvalid={errors.email !== ""}
                >
                  <FormLabel>Email Address</FormLabel>
                  <Input
                    type="text"
                    placeholder="email address"
                    borderColor="pink.500"
                    maxLength={100}
                    minLength={5}
                    value={userData.email}
                    onChange={(e) => setUserData({ ...userData, email: e.target.value.trim() })}
                  />
                  <FormErrorMessage>
                    {errors.email !== "" ? errors.email : null}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={errors.password !== ""}
                >
                  <FormLabel>Password</FormLabel>
                  <InputGroup size='md'>
                    <Input
                      borderColor="pink.500"
                      pr='4.5rem'
                      type={show ? 'text' : 'password'}
                      placeholder='password'
                      value={userData.password}
                      onChange={e => setUserData({ ...userData, password: e.target.value })}
                    />
                    <InputRightElement width='4.5rem'>
                      <Button h='1.75rem' size='sm' onClick={handleShowClick}>
                        {show ? <IoMdEyeOff size="80%" /> : <IoMdEye size="80%" />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>
                    {errors.password !== "" ? errors.password : null}
                  </FormErrorMessage>
                </FormControl>
                <Button
                  type="submit"
                  colorScheme="pink" variant="solid" width="full" mt={2}
                  isDisabled={disablePage}
                >
                  Login
                </Button>

                {errorMessage ? (
                  <Box bg="white" border="1px solid tomato" borderRadius="md">
                    <Flex align="center">
                      <Box px={2}>
                        <MdErrorOutline color="red" size={22} />
                      </Box>
                      <Text fontSize="sm" color="red" m={2}>
                        {errorMessage}</Text>
                    </Flex>
                  </Box>
                ) : null}
              </Stack>

            </form>
          </Box>
        </VStack>
      </Flex>
    </>
  )
}