import React, { useEffect, useState } from "react";
import { VStack, FormControl, FormLabel, Text, Input, Select, HStack, RadioGroup, Radio, Spinner, Box, Heading } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { ExistingSources, getExistingSources } from "../../api/go_live";
import { colorSystem } from "../../theme";

interface SourceConfig {
  botName: string;
  sourceType: string;
  countryCode: string;
  source_number: string;
  flowId: string;
  orgNames: string[];
  template_userid?: string;
  template_password?: string;
  session_userid?: string;
  session_password?: string;
  gupshup_userid?: string;
  gupshup_password?: string;
  url?: string;
  key?: string;
  namespace?: string;
  webhook_token?: string;
  auth_token?: string;
  source_id?: string;
}

interface ConfigureSourceFormProps {
  sourceConfig: SourceConfig;
  setSourceConfig: React.Dispatch<React.SetStateAction<SourceConfig>>;
  sourceOption: "existing" | "new"; // Add this line
  setSourceOption: React.Dispatch<React.SetStateAction<"existing" | "new">>; // Add this line
}

const ConfigureSource: React.FC<ConfigureSourceFormProps> = ({ sourceConfig, setSourceConfig, sourceOption, setSourceOption }) => {
  const sourceTypes = ["gupshup", "dialog-360", "valueFirst"];
  const [sourceIds, setSourceIds] = useState<string[]>([]);
  const [sourceLabels, setSourceLabels] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const data = await getExistingSources();
        setSourceIds(data.source_ids);
        // Fetch labels for these source_ids if needed
        // For now, assume sourceLabels is an empty object or can be populated from another service
        setLoading(false);
      } catch (err) {
        setError("Failed to load existing sources.");
        setLoading(false);
      }
    };
    fetchSources();
  }, []);

  return (
    <VStack align="start" spacing={4} width="100%">
      <FormControl isRequired>
        <FormLabel>Bot Name</FormLabel>
        <Input value={sourceConfig.botName} onChange={(e) => setSourceConfig({ ...sourceConfig, botName: e.target.value })} />
      </FormControl>
      <FormControl isRequired>
        <FormLabel>Source Option</FormLabel>
        <HStack spacing={12} className="pt-4 justify-center" width="100%">
          <Box
            border={sourceOption === "existing" ? `2px solid ${colorSystem["textPurple"]}` : "none"}
            className="rounded-xl cursor-pointer w-1/2 h-48 flex flex-col shadow-lg"
            onClick={() => setSourceOption("existing")}
          >
            <Box className="flex justify-center items-center text-center h-[55%] rounded-t-xl text-6xl bg-white border border-b-0 border-gray-200">
              📡
            </Box>
            <Box
              className="text-left p-4 h-[45%] rounded-b-xl"
              background="white"
              color="black"
              border="1px"
              borderColor="gray.200"
            >
              <Heading size="md">Existing Source</Heading>
              <Text className="text-slate-600">Use an existing source</Text>
            </Box>
          </Box>
          <Box
            border={sourceOption === "new" ? `2px solid ${colorSystem["textPurple"]}` : "none"}
            className="rounded-xl cursor-pointer w-1/2 h-48 flex flex-col shadow-lg"
            onClick={() => setSourceOption("new")}
          >
            <Box className="flex justify-center items-center text-center h-[55%] rounded-t-xl text-6xl bg-white border border-b-0 border-gray-200">
              📲
            </Box>
            <Box
              className="text-left p-4 h-[45%] rounded-b-xl"
              background="white"
              color="black"
              border="1px"
              borderColor="gray.200"
            >
              <Heading size="md">New Source</Heading>
              <Text className="text-slate-600">Create a new source</Text>
            </Box>
          </Box>
        </HStack>
      </FormControl>
      {sourceOption === "existing" && (
        <FormControl isRequired>
          <FormLabel>Select Source</FormLabel>
          {loading ? (
            <Spinner size="md" />
          ) : error ? (
            <Text color="red.500">{error}</Text>
          ) : (
            <Select
              value={sourceConfig.source_id || ""}
              onChange={(e) => setSourceConfig({ ...sourceConfig, source_id: e.target.value })} // Update source_id
            >
              <option value="">Select a source</option>
              {sourceIds.map((sourceId) => (
                <option key={sourceId} value={sourceId}>
                  {sourceLabels[sourceId] || sourceId} {/* Use label if available, otherwise use ID */}
                </option>
              ))}
            </Select>
          )}
        </FormControl>
      )}
      {sourceOption === "new" && (
        <>
          <HStack width="100%">
            <FormControl isRequired>
              <FormLabel>Source Number</FormLabel>
              <PhoneInput
                country={"in"}
                value={sourceConfig.source_number}
                onChange={(value) => setSourceConfig({ ...sourceConfig, source_number: value })}
                inputStyle={{
                  width: "100%",
                  borderColor: "var(--chakra-colors-gray-200)",
                  backgroundColor: "#F8F9F9",
                  height: "var(--chakra-sizes-10)",
                }}
              />
            </FormControl>
          </HStack>
          <FormControl isRequired>
            <FormLabel>Select Source</FormLabel>
            <Select value={sourceConfig.sourceType} onChange={(e) => setSourceConfig({ ...sourceConfig, sourceType: e.target.value })}>
              <option value="">Select a source type</option>
              {sourceTypes.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      {sourceConfig.sourceType === "gupshup" && (
        <>
          <FormControl isRequired>
            <FormLabel>Template User ID</FormLabel>
            <Input
              value={sourceConfig.template_userid || ""}
              onChange={(e) => setSourceConfig({ ...sourceConfig, template_userid: e.target.value })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Template Password</FormLabel>
            <Input
              type="password"
              value={sourceConfig.template_password || ""}
              onChange={(e) => setSourceConfig({ ...sourceConfig, template_password: e.target.value })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Session User ID</FormLabel>
            <Input value={sourceConfig.session_userid || ""} onChange={(e) => setSourceConfig({ ...sourceConfig, session_userid: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Session Password</FormLabel>
            <Input
              type="password"
              value={sourceConfig.session_password || ""}
              onChange={(e) => setSourceConfig({ ...sourceConfig, session_password: e.target.value })}
            />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Gupshup User ID</FormLabel>
            <Input value={sourceConfig.gupshup_userid || ""} onChange={(e) => setSourceConfig({ ...sourceConfig, gupshup_userid: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Gupshup Password</FormLabel>
            <Input
              type="password"
              value={sourceConfig.gupshup_password || ""}
              onChange={(e) => setSourceConfig({ ...sourceConfig, gupshup_password: e.target.value })}
            />
          </FormControl>
        </>
      )}
      {sourceConfig.sourceType === "dialog-360" && (
        <>
          <FormControl isRequired>
            <FormLabel>URL</FormLabel>
            <Input value={sourceConfig.url || ""} onChange={(e) => setSourceConfig({ ...sourceConfig, url: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Key</FormLabel>
            <Input value={sourceConfig.key || ""} onChange={(e) => setSourceConfig({ ...sourceConfig, key: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Namespace</FormLabel>
            <Input value={sourceConfig.namespace || ""} onChange={(e) => setSourceConfig({ ...sourceConfig, namespace: e.target.value })} />
          </FormControl>
          <FormControl isRequired>
            <FormLabel>Webhook Token</FormLabel>
            <Input value={sourceConfig.webhook_token || ""} onChange={(e) => setSourceConfig({ ...sourceConfig, webhook_token: e.target.value })} />
          </FormControl>
        </>
      )}
      {sourceConfig.sourceType === "valueFirst" && (
        <>
          <FormControl isRequired>
            <FormLabel>Auth Token</FormLabel>
            <Input value={sourceConfig.auth_token || ""} onChange={(e) => setSourceConfig({ ...sourceConfig, auth_token: e.target.value })} />
          </FormControl>
        </>
      )}
    </VStack>
  );
};

export default ConfigureSource;
