import React, { useState, useEffect, forwardRef } from "react";
import {
  Box,
  Heading,
  Image,
  Stack,
  StackProps,
  HStack,
  Tag,
  TagLabel,
  Text,
  Wrap,
} from "@chakra-ui/react";

import { arvindCampaignCategories } from "../utils/constants";
import { getFlowTags, getFlowStatusBreakdown, getFlowSelectionStatusBreakdown } from "../api/flow";
import { FilterQuery } from "../components/Filter/models";
import { useStoreActions } from "../hooks/store";
import { useAuthToken } from "../hooks/useAuthToken";
import { Dropdown } from "../components/Dropdown";
import {
  attributesIconSrc,
  campaignsIconSrc,
  CloseIcon,
  InfoIcon,
  statusIconSrc,
  tagsIconSrc,
  selectionStatusIconSrc
} from "../constants/icons";
import { TagGroup } from "../components/TagGroup";
import { useCampaignConfig } from "../data/analytics/useConfig";
import { useAttributeKeys } from "../data/flow/useAttributeKeys";
import { useDashboardFlowConfig } from "../data/flow/useDashboardFlow";
import {
  SelectAttributeValue,
  AttributeFilterQuery,
} from "../components/Input";
import {
  useFilterAttributes,
  AttributeFilter,
} from "../mutations/users/useFilterAttributes";

type Props = {
  flowId: string;
};

export const SelectExistingUsers = ({ flowId }: Props) => {
  const { getAuthToken } = useAuthToken();
  const storeFilters = useStoreActions((actions) => actions.cacheNewUsers);

  const { data: flowCampaigns } = useCampaignConfig(flowId);
  const { data: attributeKeys } = useAttributeKeys(flowId);
  const { data: flowConfig } = useDashboardFlowConfig(flowId);
  const getUsersByAttributes = useFilterAttributes({ flowId });

  const [flowStatusCount, setFlowStatusCount] = useState<{
    [x: string]: number;
  }>({});

  // global filters applied to all users in the flow (tags, status, campaigns, attributes)
  const [allFilters, setAllFilters] = useState<FilterQuery[]>([]);
  const [attributeFilters, setAttributeFilters] = useState<
    AttributeFilterQuery[]
  >([]);

  // used to display the selected filters
  const [flowTags, setFlowTags] = useState<string[]>([]);
  const [flowStatus, setFlowStatus] = useState<string[]>([]);
  const [flowSelectionStatus, setFlowSelectionStatus] = useState<string[]>([]);

  // stores the selected filters
  const [selectedFlowStatus, setSelectedFlowStatus] = useState<string[]>([]);
  const [selectedFlowSelectionStatus, setSelectedFlowSelectionStatus] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<
    { key: string; name: string; isExternal: boolean }[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([
    arvindCampaignCategories[0],
  ]);

  const storeCampaignType = ["arvind-corp", "ARVIND_RETAIL"].includes(flowId);

  const getAttributes = () => {
    let attributes: string[] = [];

    if (attributeKeys) {
      attributes = [...attributeKeys];
    }

    if (flowConfig) {
      attributes = [
        ...attributes,
        ...Object.keys(flowConfig?.dashboard?.summary ?? {}),
      ];
    }

    return attributes;
  };

  const parseSelectedAttributes = (selectedKeys: string[]) => {
    let selected: { key: string; name: string; isExternal: boolean }[] = [];

    if (attributeKeys) {
      attributeKeys
        .filter((key) => selectedKeys.includes(key))
        .map((key) =>
          selected.push({
            key,
            name: key,
            isExternal: true,
          })
        );
    }

    if (flowConfig) {
      Object.keys(flowConfig?.dashboard?.summary ?? {})
        .filter((key) => selectedKeys.includes(key))
        .map((flowAttribute) =>
          selected.push({
            isExternal: false,
            key:
              flowConfig?.dashboard?.summary?.[flowAttribute] ?? flowAttribute,
            name: flowAttribute,
          })
        );
    }

    return selected;
  };

  const getSelectedUsersCount = () => {
    let selectedUsersCount = 0;
    Object.keys(flowStatusCount).map(
      (status) => (selectedUsersCount += flowStatusCount[status])
    );
    return selectedUsersCount;
  };

  const updateFilters = (filters: FilterQuery[]) => {
    const filterKeysToUpdate = filters.map((filter) => filter.key);
    const existingFilters = allFilters.filter(
      (filter) => !filterKeysToUpdate.includes(filter.key)
    );

    // remove null or empty filters
    filters = filters.filter((filter) =>
      filter.value
        ? typeof filter.value === "object"
          ? Object.keys(filter.value).length > 0
          : filter.value
        : false
    );

    console.log("removed useless filters", filters);

    setAllFilters([...existingFilters, ...filters]);
  };

  useEffect(() => {
    const fetchTags = async (token: string) => {
      const response = await getFlowTags({
        token,
        flowId,
        filters: [],
      });

      if (response.tags) setFlowTags(Object.keys(response.tags));
    };

    const fetchFlowStatus = async (token: string) => {
      const response = await getFlowStatusBreakdown({
        flowId,
        token,
        filters: [],
      });

      setFlowStatus(Object.keys(response.status));
    };
    const fetchFlowSelectionStatus = async (token: string) => {
      const response = await getFlowSelectionStatusBreakdown({
        flowId,
        token,
        filters: [],
      });

      setFlowSelectionStatus(Object.keys(response.selectionStatus));
    };

    const fetchFlowInfo = async () => {
      const token = localStorage.getItem('token')

      await Promise.all([fetchTags(token), fetchFlowStatus(token), fetchFlowSelectionStatus(token)]);


      setAllFilters([]);
    };

    fetchFlowInfo();
  }, []);

  useEffect(() => {
    const getFilteredUsersCount = async () => {
      const token = localStorage.getItem('token')
      const response = await getFlowStatusBreakdown({
        flowId,
        token,
        filters: allFilters,
      });

      setFlowStatusCount(response.status);
    };

    getFilteredUsersCount();
    return () => {
      storeFilters([{ filters: allFilters }]);
    };
  }, [allFilters]);

  useEffect(() => {
    const updateStatusFilter = () => {
      let filterQuery: FilterQuery = {
        key: "data.dashboard_status",
        operation: "in",
        value: selectedFlowStatus,
      };

      updateFilters([filterQuery]);
    };

    updateStatusFilter();
  }, [selectedFlowStatus]);

  useEffect(() => {
    const updateSelectionStatusFilter = () => {
      let filterQuery: FilterQuery = {
        key: "data.selection_status",
        operation: "in",
        value: selectedFlowSelectionStatus,
      };

      updateFilters([filterQuery]);
    };

    updateSelectionStatusFilter();
  }, [selectedFlowSelectionStatus]);

  useEffect(() => {
    const updateTagsFilter = () => {
      let filterQuery: FilterQuery = {
        key: "data.tags",
        operation: "in",
        value: selectedTags,
      };

      updateFilters([filterQuery]);
    };

    updateTagsFilter();
  }, [selectedTags]);

  useEffect(() => {
    const updateCampaignFilter = () => {
      let filterQuery: FilterQuery = {
        key: "data.campaign_id",
        operation: "in",
        value: flowCampaigns
          ? Array.isArray(flowCampaigns)
            ? flowCampaigns
                .filter((flowCampaign) =>
                  selectedCampaigns.includes(flowCampaign.name)
                )
                .map((campaign) => campaign.campaign_id)
            : []
          : [],
      };

      updateFilters([filterQuery]);
    };

    updateCampaignFilter();
  }, [selectedCampaigns]);

  useEffect(() => {
    // generates filter query to be added to allFilters
    const generateFilterQuery = async () => {
      if (attributeFilters.length === 0) {
        // remove attribute filters
        return updateFilters([
          {
            key: "conversation_id",
            value: [],
            operation: "in",
          },
        ]);
      }

      let attributeFilterList: AttributeFilter[] = [];
      let attributeFilterQuery: FilterQuery[] = [];

      attributeFilters.map((attributeFilter) => {
        if (attributeFilter.isExternal) {
          if (attributeFilter.value) {
            attributeFilterList.push({
              attribute_key: attributeFilter.key,
              attribute_value: attributeFilter.value,
              operation: attributeFilter.operation === "in" ? "in" : "nin",
            });
          }
        } else {
          attributeFilterQuery.push({
            key: `data.${attributeFilter.key}`,
            operation: attributeFilter.operation,
            value: attributeFilter.value,
          });
        }
      });

      if (attributeFilterList.length !== 0) {
        const response = await getUsersByAttributes.mutateAsync(
          attributeFilterList
        );

        if (response && response.length !== 0) {
          attributeFilterQuery.push({
            key: "conversation_id",
            value: response,
            operation: "in",
          });
        }
        // when there are no users with the selected attributes
        else {
          attributeFilterQuery.push({
            key: "conversation_id",
            value: [null],
            operation: "in",
          });
        }
      } else {
        attributeFilterQuery.push({
          key: "conversation_id",
          value: [],
          operation: "in",
        });
      }

      return updateFilters(attributeFilterQuery);
    };

    generateFilterQuery();
  }, [attributeFilters]);

  return (
    <Stack spacing={8} flex="1">
      <Box>
        <Heading className="text-left">who?</Heading>
        <p className="text-left text-xl">who do you wanna send invites to?</p>
      </Box>

      {/* Selecting Filters */}
      <HStack>
        <Dropdown
          key={`select_tags`}
          variant="secondary"
          leftIcon={<Image src={tagsIconSrc} />}
          options={flowTags}
          defaultSelected={selectedTags}
          onChange={(tags) => setSelectedTags(tags)}
          helperText="No tags to choose from."
        >
          Tags
        </Dropdown>
        <Dropdown
          key={`select_attributes`}
          variant="secondary"
          leftIcon={<Image src={attributesIconSrc} />}
          options={getAttributes()}
          defaultSelected={selectedAttributes.map(
            (attribute) => attribute.name
          )}
          onChange={(attributes) => {
            setSelectedAttributes(parseSelectedAttributes(attributes));
            // setAttributeFilters((filters) =>
            //   filters.filter((filter) => attributes.includes(filter.key))
            // );
          }}
          helperText="No attributes to choose from."
        >
          Attributes
        </Dropdown>
        <Dropdown
          key={`select_campaign`}
          variant="secondary"
          leftIcon={<Image src={campaignsIconSrc} />}
          options={
            flowCampaigns
              ? Array.isArray(flowCampaigns)
                ? flowCampaigns.map((value) => value.name ?? value.campaign_id)
                : []
              : []
          }
          defaultSelected={selectedCampaigns}
          onChange={(campaigns) => setSelectedCampaigns(campaigns)}
          helperText="No campaigns to choose from."
        >
          Campaigns
        </Dropdown>
        <Dropdown
          key={`select_status`}
          variant="secondary"
          leftIcon={<Image src={statusIconSrc} />}
          options={flowStatus}
          defaultSelected={selectedFlowStatus}
          onChange={(status) => setSelectedFlowStatus(status)}
          helperText="No status to choose from."
        >
          Status
        </Dropdown>
        <Dropdown
          key={`select_selection_status`}
          variant="secondary"
          leftIcon={<Image src={selectionStatusIconSrc} />}
          options={flowSelectionStatus}
          defaultSelected={selectedFlowSelectionStatus}
          onChange={(status) => setSelectedFlowSelectionStatus(status)}
          helperText="No selection status to choose from."
        >
          Selection Status
        </Dropdown>
      </HStack>

      {/* Showing added filters */}
      <Stack>
        <Heading size="sm">Added Filters:</Heading>
        <AddedFilterListItem
          key="added_tags_list"
          title="Tags"
          filtered={selectedTags}
          hidden={selectedTags.length === 0}
          onRemove={(removedTag) =>
            setSelectedTags((prev) => prev.filter((tag) => tag !== removedTag))
          }
        />
        <Stack
          p={4}
          borderWidth="1px"
          borderRadius="md"
          hidden={selectedAttributes.length === 0}
        >
          <Text>Attributes</Text>
          <Stack>
            {selectedAttributes.map((selectedAttribute, index) => (
              <SelectAttributeValue
                w="full"
                flowId={flowId}
                key={`selected_attribute_${selectedAttribute.key}`}
                attributeKey={selectedAttribute.key}
                attributeTitle={selectedAttribute.name}
                isExternal={selectedAttribute.isExternal}
                onChange={(query) => {
                  setAttributeFilters((filters) => [
                    ...filters.filter(
                      (filter) => filter.key !== selectedAttribute.key
                    ),
                    query,
                  ]);
                }}
                onRemove={() => {
                  setSelectedAttributes((attributes) =>
                    attributes.filter(
                      (attribute) => attribute.name !== selectedAttribute.name
                    )
                  );
                  setAttributeFilters((filters) =>
                    filters.filter(
                      (filter) => filter.key !== selectedAttribute.key
                    )
                  );
                }}
              />
            ))}
          </Stack>
        </Stack>
        <AddedFilterListItem
          key="added_campaings_list"
          title="Campaigns"
          filtered={selectedCampaigns}
          hidden={selectedCampaigns.length === 0}
          onRemove={(removedCampaign) =>
            setSelectedCampaigns((prev) =>
              prev.filter((tag) => tag !== removedCampaign)
            )
          }
        />
        <AddedFilterListItem
          key="added_status_list"
          title="Status"
          filtered={selectedFlowStatus}
          hidden={selectedFlowStatus.length === 0}
          onRemove={(removedStatus) =>
            setSelectedFlowStatus((prev) =>
              prev.filter((tag) => tag !== removedStatus)
            )
          }
        />
        <AddedFilterListItem
          key="added_selection_status_list"
          title="Selection Status"
          filtered={selectedFlowSelectionStatus}
          hidden={selectedFlowSelectionStatus.length === 0}
          onRemove={(removedStatus) =>
            setSelectedFlowSelectionStatus((prev) =>
              prev.filter((tag) => tag !== removedStatus)
            )
          }
        />
      </Stack>
      <Stack hidden={!storeCampaignType}>
        <Heading size="sm">Category:</Heading>
        <Box bg="white" flexGrow={1} borderRadius="md" p={2} borderWidth="1px">
          <TagGroup
            placeholder="Select category"
            tags={selectedCategory}
            options={arvindCampaignCategories}
            allowNewTag={false}
            onChange={(tags) =>
              setSelectedCategory(typeof tags === "string" ? [tags] : tags)
            }
          />
        </Box>
      </Stack>
      <Stack direction="row" justify="flex-start">
        <InfoIcon size={24} />
        <Text fontSize="md">
          You have selected <b>{getSelectedUsersCount()}</b> existing users
        </Text>
      </Stack>
    </Stack>
  );
};

interface AddedFilterListItemProps extends StackProps {
  title: string;
  filtered?: string[];
  onRemove?: (filter: string) => void;
}

const AddedFilterListItem = forwardRef<
  HTMLDivElement,
  AddedFilterListItemProps
>(({ title, filtered = [], onRemove, ...props }, ref) => {
  return (
    <Stack ref={ref} p={4} borderWidth="1px" borderRadius="md" {...props}>
      <Text>{title}</Text>
      <Wrap>
        {filtered.map((tag, index) => (
          <Tag key={`filtered_${title}_${tag}_${index}`}>
            <TagLabel>{tag}</TagLabel>
            <Box pl={2} _hover={{ cursor: "pointer" }}>
              <CloseIcon onClick={() => onRemove?.(tag)} />
            </Box>
          </Tag>
        ))}
      </Wrap>
    </Stack>
  );
});
