import React, { forwardRef } from "react";
import {
  Box,
  Text,
  BoxProps,
  HStack,
  AvatarGroup,
  Stack,
  Heading,
  Avatar,
} from "@chakra-ui/react";

import { getRandomGradient, getRandomAvatar } from "../utils/generate";
import { getBotType } from "../utils/helpers";

interface FlowRecordProps extends BoxProps {
  name: string;
  type?: string;
  icon?: string;
  count?: number;
}

export const FlowRecordTile = forwardRef<HTMLDivElement, FlowRecordProps>(
  ({ name, icon, type = "Hiring", count, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        p={4}
        boxShadow="sm"
        borderWidth="thin"
        borderRadius="md"
        bg="gray.100"
        _hover={{
          bg: "gray.200",
          boxShadow: "md",
          cursor: "pointer",
        }}
        {...props}
      >
        <HStack className="justify-between items-center">
          <AvatarGroup size="lg" max={2} mb={8}>
            <Avatar
              name="Logo"
              background={getRandomGradient(name)}
              // src={getRandomAvatar(name)}
            />
          </AvatarGroup>
          {count && (
            <Stack spacing={0} align="end">
              <Heading size="md">{count.toLocaleString()}</Heading>
              <Text>Active users</Text>
            </Stack>
          )}
        </HStack>
        <Heading size="sm">{name}</Heading>
        <Text opacity={0.8}>{getBotType(type)}</Text>
      </Box>
    );
  }
);
