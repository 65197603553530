import { useQuery } from "@tanstack/react-query";
import { useFetchWithAuth } from "../base/useFetchWithAuth";
import config from "../../config";

export interface UserSummary {
  table: {
    [x: string]: any;
  };
  summary: {
    [x: string]: any;
  };
  overview: {
    [x: string]: any;
  };
  test_results?: {
    [x: string]: any;
  };
  video_test_results?: string;
  resume?: Record<string, any>;
  blocked?: boolean;
}

export const useUserSummary = ({
  flowId,
  userId = "",
  sessionId = "",
}: {
  flowId: string;
  userId?: string;
  sessionId?: string;
}) => {
  const fetchWithAuth = useFetchWithAuth();

  const checkIfBlocked = (attributes?: { [attributeKey: string]: any }) => {
    if (!attributes) return false;

    if (attributes.Status) return attributes.Status === "Not Interested";

    return false;
  };

  const fetchUserData = async () => {
    const res = await fetchWithAuth<UserSummary>({
      url: `${config.url}/v2/users/user/${flowId}`,
      config: {
        params: {
          user_id: userId,
          session_id: sessionId,
        },
      },
    });

    if (res) {
      res.blocked = checkIfBlocked(res?.table ?? {});
    }

    return res;
  };

  return useQuery(["users", flowId, `${userId}${sessionId}`], fetchUserData, {
    keepPreviousData: true,
    refetchInterval: 1000 * 10, // 30 seconds
  });
};
