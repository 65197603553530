import React, { useState, useEffect } from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Progress, Stack, Text, useToast } from "@chakra-ui/react";

import { Button } from "./Button";
import { ExportIcon } from "../constants/icons";

import config from "../config";
import { SortGroup } from "../api/users";
import { FilterQuery } from "./Filter/models";
import { useAuthToken } from "../hooks/useAuthToken";

interface FlowExportProps {
    exportMsgBetween: any[]
    msgFileName: string
    flowId: string
  }

class RetriableError extends Error {}
class FatalError extends Error {}

export const Exportinvitations = ({exportMsgBetween, msgFileName, flowId}: FlowExportProps) => {
  const toastMessage = useToast();
  const { getAuthToken } = useAuthToken();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [finishFlag, setFinishFlag] = useState(false);
  

  useEffect(() => {
    if (finishFlag) generateCSV();
  }, [users, finishFlag]);

  const exportUsers = async () => {
    const token = await getAuthToken();
    const data1 ={
        "start_date" : `${exportMsgBetween[0]}`,
        "end_date": `${exportMsgBetween[1]}`
    }
    const eventSource = await fetchEventSource(
      `${config.url}/export_latest_users/${flowId}`,
      {
        headers: {
          Accept: "text/event-stream",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify(data1),
        // TIL: understood how important this flag is
        openWhenHidden: true,
        onopen: async (response) => {
          if (response.ok) {
            console.log("starting the flow export");
            setUsers([]);
            setLoading(true);
          } else if (
            response.status >= 400 &&
            response.status < 500 &&
            response.status !== 429
          ) {
            // client-side errors are usually non-retriable:
            console.log("client-side errors are usually non-retriable:");
            setLoading(false);
            setUsers([]);
            throw new FatalError();
          } else {
            console.log("errors that are usually retriable");
            setLoading(false);
            setUsers([]);
            throw new RetriableError();
          }
        },

        onmessage: (event) => {
          setUsers((data) => [...data, event.data]);
        },

        onclose: () => {
          console.log("export finished and server closed");
          setFinishFlag(true);
        },
      }
    );

    return eventSource;
  };

  const handleExportError = () => {
    setLoading(false);
    setTimeout(() => {
      toastMessage({ title: "Export failed", status: "error" });
    }, 500);
  };

  const generateCSV = () => {
    try {
      console.log(`exported ${users.length} users`);

      const a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);

      const blobFile = new Blob(users, { type: "text/csv" });
      const url = window.URL.createObjectURL(blobFile);
      a.href = url;
      a.download = `${msgFileName}.csv`;
      a.click();
      setLoading(false);
      setFinishFlag(false);
      setUsers([]);
      return window.URL.revokeObjectURL(url);
    } catch (error) {
      handleExportError();
    }
  };

  const getExportProgress = (): number => {
    const progress = Math.floor((users.length / 100) * 100);
    console.log(progress)
    return progress > 100 ? 100 : progress;
  };

  if (loading) {
    return (
      <Stack align="center" w="48" spacing={1}>
        <Stack direction="row">
          <Text color="pink.500">{getExportProgress()}%</Text>
          <Text>Export in progress..</Text>
        </Stack>
        <Progress
          colorScheme="pink"
          borderRadius="full"
          value={getExportProgress()}
          size="sm"
          w="full"
          hasStripe
        />
      </Stack>
    );
  }

  return (
    <Button
      variant="secondary"
      leftIcon={<ExportIcon />}
      onClick={() => exportUsers()}
    >
      Export invitations
    </Button>
  );
};
