import React, { useState, useEffect, useRef } from "react";
import { Box, Flex, Alert, AlertIcon, Table, Thead, Tbody, Tr, Th, Td, Button, Text, Heading, Card } from "@chakra-ui/react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip as ChartTooltip } from "chart.js";
import { Bar } from "react-chartjs-2";
import FullPageLoader from "./Loaders/FullPageLoader";
import { getBotAnalyticsData, getFlowUsers } from "../api/bot_analytics";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, ChartTooltip);

const BotAnalytics = () => {
  const [botData, setBotData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [flowUsers, setFlowUsers] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await getBotAnalyticsData();
        if (data && data.bot_analytics_data && Array.isArray(data.bot_analytics_data)) {
          setBotData(data.bot_analytics_data);
          setTotalUsers(data.total_active_users);
          setError(null);
        } else {
          throw new Error("Unexpected data structure in API response");
        }
      } catch (err) {
        console.error("Error details:", err);
        setError(err.message || "Failed to fetch bot analytics data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const getColor = (users, botData) => {
    const maxUsers = Math.max(...botData.map((bot) => bot.liveUsers));
    const minUsers = Math.min(...botData.map((bot) => bot.liveUsers));

    const range = maxUsers - minUsers;
    const percentage = range === 0 ? 1 : (users - minUsers) / range;

    const green = [75, 192, 92]; // Bright green for high user counts
    const red = [255, 99, 132]; // Red for low user counts

    const color = green.map((g, i) => Math.round(g + (red[i] - g) * (1 - percentage)));

    return `rgb(${color.join(",")})`;
  };

  const fetchFlowUsers = async (flowId) => {
    try {
      setIsLoading(true);
      const data = await getFlowUsers(flowId);
      setFlowUsers(data);
      setError(null);
    } catch (err) {
      setError(err.message || "Failed to fetch flow users data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleBarClick = (event, elements) => {
    if (elements.length > 0) {
      const dataIndex = elements[0].index;
      const flowId = sortedBotData[dataIndex].flow_id;
      setSelectedFlow(flowId);
      fetchFlowUsers(flowId);
    }
  };

  const formatDate = (dateString: string): string => {
    try {
      const date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "long",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return date.toLocaleString("en-US", options);
    } catch (error) {
      console.error("Error formatting date:", error);
      return dateString; // Return the original string if formatting fails
    }
  };

  if (isLoading) {
    return <FullPageLoader />;
  }

  if (error) {
    return (
      <Box p={6}>
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }

  const sortedBotData = [...botData].sort((a, b) => b.liveUsers - a.liveUsers);

  const chartData = {
    labels: sortedBotData.map((bot) => bot.source),
    datasets: [
      {
        data: sortedBotData.map((bot) => bot.liveUsers),
        backgroundColor: sortedBotData.map((bot) => getColor(bot.liveUsers, sortedBotData)),
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    onClick: handleBarClick,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const data = sortedBotData[context.dataIndex];
            return [
              `Bot Name: ${data.source}`,
              `Flow-Id: ${data.flow_id}`,
              `Live Users: ${data.liveUsers}`,
              `Last Active On: ${formatDate(data.lastActiveOn)}`,
            ];
          },
        },
      },
      legend: {
        display: false,
      },
    },
    barPercentage: 1,
    categoryPercentage: 1,
    maxBarThickness: 40, // This sets the maximum width of a bar to 10 pixels
  };

  return (
    <Box p={6}>
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="lg">Bot Analytics</Heading>
        <Button colorScheme="blue">Total Users: {totalUsers}</Button>
      </Flex>
      <Card p={6} mb={6}>
        <Text fontSize="sm" color="gray.600" mb={4}>
          Total Active Bots: {botData.length}
        </Text>
        <Box height="400px">
          <Bar ref={chartRef} data={chartData} options={chartOptions} />
        </Box>
      </Card>

      {selectedFlow && (
        <Card p={6}>
          <Heading size="md" mb={4}>
            {selectedFlow}
          </Heading>
          <Table variant="striped">
            <Thead>
              <Tr>
                <Th>Username</Th>
                <Th>Last Active On</Th>
                <Th>Last Message</Th>
              </Tr>
            </Thead>
            <Tbody>
              {flowUsers.map((user, index) => (
                <Tr key={index}>
                  <Td>{user.name}</Td>
                  <Td>{formatDate(user.lastActiveOn)}</Td>
                  <Td>{user.last_message_text}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
      )}
    </Box>
  );
};

export default BotAnalytics;
