import React from "react";
import {
  Tag,
  TagLabel,
  CircularProgress,
  Tooltip,
  TagRightIcon,
  BoxProps,
  Circle,
  TagLeftIcon,
} from "@chakra-ui/react";
import { getTagColor } from "../utils/helpers";

interface StatusTagProps extends BoxProps {
  tag: string;
  isLoading?: boolean;
  size?: "lg" | "md" | "sm" | "xs" | string;
  variant?: "status" | "tag";
  showTooltip?: boolean;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  showDot?: boolean;
  onClick?: () => void;
  colorScheme?: string; // Add this line
}

const StatusTag = ({
  tag = "",
  size = "md",
  isLoading = false,
  showTooltip = false,
  showDot = false,
  variant = "status",
  colorScheme, // Add this line
  ...rest
}: StatusTagProps) => {
  let color = colorScheme ? `${colorScheme}.500` : getTagColor(tag);

  if (tag === "") {
    return <p>{tag}</p>;
  }

  return (
    <Tooltip label={showTooltip && tag}>
      <Tag
        size={size}
        colorScheme={colorScheme} // Add this line
        style={
          variant === "status"
            ? {
                cursor: "pointer",
                color: "black",
                backgroundColor: colorScheme ? undefined : color,
                height: size === "sm" ? "1rem" : "auto",
              }
            : {
                cursor: "pointer",
                color: "black",
                borderColor: colorScheme ? undefined : color,
                borderWidth: "thin",
                height: size === "sm" ? "1rem" : "auto",
              }
        }
        borderRadius={showDot ? "full" : "sm"}
        onClick={() => rest.onClick?.()}
        {...rest}
      >
        {isLoading && (
          <CircularProgress isIndeterminate size={5} marginRight={1} />
        )}
        {rest.leftIcon && <TagLeftIcon>{rest.leftIcon}</TagLeftIcon>}
        {showDot && <Circle mr={2} size="2" bg="blackAlpha.400" />}
        <TagLabel>{tag}</TagLabel>
        {rest.rightIcon && (
          <TagRightIcon cursor="pointer">{rest.rightIcon}</TagRightIcon>
        )}
      </Tag>
    </Tooltip>
  );
};

export default StatusTag;