import React from "react";
import * as SentryProvider from "@sentry/react";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import NotFound from "../pages/NotFound";
import {
  History,
  Knowledge,
  Support,
  Users,
  Whatsapp,
  InviteHome,
  Metrics,
  Playground,
  ConversationBuilder,
} from "../pages/Flow";
import DhwaniAnalytics from "../pages/DhwaniAnalytics.jsx";
import { Login } from "../pages/Login";
import { Home } from "../pages/Home";
import { AuthenticatedRoute } from "./protected";
import { PageLayout } from "./layout";
import { ResetUsers } from "../redirect/reset";
import { UserSummary } from "../views/UserProfile";
import { DefaultFlowConversation } from "../pages/Flow/Conversation";
import { Settings } from "../pages/Flow/Settings";
import {
  General,
  Members,
  QuickReplies,
  WebChat

} from "../pages/Flow/Settings/Support/";




import { HypdAnalytics } from "../pages/HypdAnalytics/";
import AddNew from "../pages/Flow/AddNew/AddNew";
import { SupportUserChat } from "../pages/Flow/Support/Chat";
import { ErrorBoundary } from "../pages/Error";

const sentryCreateBrowserRouter =
  SentryProvider.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route path="/" element={<AuthenticatedRoute />}>
        <Route path="/" element={<Home />} />
        <Route path="/botanalytics" element={<Home />} />
        <Route path="/go_live" element={<Home />} />
        <Route path="/conversation/:id" element={<ConversationBuilder />} />
        <Route path="/dashboard" element={<PageLayout />}>
          <Route path=":id/" element={<Users />} />
          <Route path=":id/metrics" element={<Metrics />} />
          {/* Users */}
          <Route path=":id/users" element={<Users />} />
          <Route path=":id/users/new" element={<InviteHome />} />
          <Route path=":id/users/reset" element={<ResetUsers />} />
          <Route path=":id/user/:sessionId/:userId" element={<UserSummary />} />

          {/* Analytics */}
          <Route path=":id/analytics" element={<HypdAnalytics />} />
          <Route path=":id/analytics/dhwani" element={<DhwaniAnalytics />} />

          <Route path=":id/whatsapp" element={<Whatsapp />} />
          <Route
            path=":id/conversations"
            element={<DefaultFlowConversation />}
          />
          <Route path=":id/knowledge" element={<Knowledge />} />
          <Route path=":id/support" element={<Support />}>
            <Route path=":sessionId/:userId" element={<SupportUserChat />} />
          </Route>
          <Route path=":id/history" element={<History />} />
          <Route path=":id/new" element={<AddNew />} />
          <Route path=":id/playground" element={<Playground />} />

          {/* Settings */}
          <Route path=":id/settings" element={<Settings />}>
            <Route path="" element={<General />} />
            <Route path="support/teams" element={<Members />} />
            <Route path="support/quick_replies" element={<QuickReplies />} />
            <Route path="support/web_chat" element={<WebChat />} />
          </Route>

          <Route
            path=":id/new/conversation"
            element={<ConversationBuilder />}
          />
        </Route>
      </Route>
      <Route path="login" element={<Login />} />
      <Route
        path="invalid_access"
        element={
          <NotFound
            title="This version of bot can't support this feature"
            subtitle="Upgrade to experience this feature"
          />
        }
      />
    </Route>
  )
);
