import React from 'react';
import { Box, Button } from '@chakra-ui/react';

interface NewBotConfigProps {
  onBack: () => void;
}

const NewBotConfig: React.FC<NewBotConfigProps> = ({ onBack }) => {
  return (
    <Box>
      <h1>Configure New Bot</h1>
      {/* Add your configuration form or components here */}
      <Button onClick={onBack}>Back</Button>
    </Box>
  );
};

export default NewBotConfig;