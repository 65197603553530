import React, { useState, useEffect } from "react";
import {
  Box,
  ButtonGroup,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Input,
  useToast,
  useDisclosure,
  Text,
  Checkbox,
  Radio,
  RadioGroup,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Button as ChakraButton,
  TableContainer,
  Table,
  Tbody,
  Td,
  Thead,
  Tr,
  Th,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Flex
} from "@chakra-ui/react";
import { useNavigate ,useLocation} from "react-router-dom";
import { BsClock } from "react-icons/bs";

import { BaseInviteLayout, InviteViewProps } from "./BaseLayout";
import { Button } from "../../../components/Button";
import { WhatsAppTemplate } from "../../../components/WhatsAppTemplate";
import { useStoreActions, useStoreState } from "../../../hooks/store";
import { getUsers } from "../../../api/users";
import { defaultTableColumns, WEEKDAYS } from "../../../constants";
import { getUniqueCampaignName } from "../../../utils/helpers";
import {
  generateCSVMapping,
  generateInviteUsers,
  generateTimestamp,
  getAttributeKeysFromCSV,
} from "../../../utils";
import { FilterGroupQuery } from "../../../components/Filter/models";
import {
  unifiedInviteAPI,
  slackInviteAPI,
  abcAPI, // import the abcAPI function
  RepeatTiming,
  Month,
  WeekDays,
  Days,
  Minutes,
  Hours,
} from "../../../api/invite";
import { ChevronDownIcon } from "../../../constants/icons";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { useFlowInfo } from "../../../hooks/useFlowInfo";
import chatImage from "../../../img/chat_bg.png";

type RepeatType = "Daily" | "Once" | "Custom";
type EndsType = "Never" | "Custom";

const generateRepeatTiming = (
  date: string,
  time: string = "00 : 00",
  weekdays: string | string[] = []
) => {
  const selectedDate = new Date(date);
  const [hh, mm] = time.split(":");

  let selectedWeekDays = [];

  if (typeof weekdays === "string") {
    if (weekdays === "Once") selectedWeekDays = [];
    else selectedWeekDays = [0, 1, 2, 3, 4, 5, 6];
  } else {
    selectedWeekDays = weekdays.map(
      (weekday) => WEEKDAYS.indexOf(weekday) as WeekDays
    );
  }

  const timing: RepeatTiming = {
    years: [selectedDate.getFullYear()],
    months: [(selectedDate.getMonth() + 1) as Month],
    days: [selectedDate.getDate() as Days],
    weekdays: selectedWeekDays,
    hours: [parseInt(hh) as Hours],
    minutes: [parseInt(mm) as Minutes],
  };

  return timing;
};

export const ReviewInvite = ({ flowId,selectedChannel,subChannel, ...props }: InviteViewProps) => {
  console.log(selectedChannel)
  console.log(subChannel)

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOption, setSelectedOption] = useState(selectedChannel);
  const [subOptionsVisible, setSubOptionsVisible] = useState(false);
  const [subOption, setSubOption] = useState("");

  const {
    user: { email: userMail, username: userName },
    getAuthToken } = useAuthToken();
  const location = useLocation();
  const { channel, users: locationUsers } = location.state || {};
  const flowInfo = useFlowInfo(flowId);
  const toastMessage = useToast();
  const navigate = useNavigate();

  const cachedTemplate = useStoreState((state) => state.template);
  const selectedUsers = useStoreState((state) => state.newUsers);
  const selectedTemplate = useStoreState((state) => state.template);
  const campaignData = useStoreState((state) => state.campaignData);

  const removeCachedUsers = useStoreActions((actions) => actions.clearNewUsers);
  const clearCampaignData = useStoreActions(
    (actions) => actions.clearCampaignData
  );

  const [loading, setLoading] = useState(false);
  const [inviteExisting, setInviteExisting] = useState(false);
  const [users, setUsers] = useState([]);

  const [campaignName, setCampaignName] = useState(getUniqueCampaignName());
  const [tableColumns, settableColumns] = useState(defaultTableColumns);

  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [startingTime, setStartingTime] = useState("");
  const [repeatType, setRepeatType] = useState<RepeatType>("Daily");
  const [endsOn, setEndsOn] = useState<EndsType>("Never");
  const [customRepeat, setCustomRepeat] = useState([]);

  useEffect(() => {
    const getFilteredUsers = async () => {
      const token = localStorage.getItem('token')
      const inviteType = selectedUsers.length > 0 ? selectedUsers[0] : {};

      if (Object.keys(inviteType).length === 0) return;

      if (inviteType.filters) {
        setLoading(true);
        try {
          const response = await getUsers({
            flowId,
            token,
            filters: inviteType.filters,
          });

          setLoading(false);
          if (response)
            return setUsers(response.users.map((user) => user.data));
        } catch (error) {
          setLoading(false);
        }
      } else {
        setUsers(selectedUsers);
        setLoading(false);
      }
    };

    getFilteredUsers();
  }, []);

  useEffect(() => {
    const generateTableHeaders = () => {
      if (users.length > 0) {
        let headers = generateCSVMapping(users);
        let columns = [];

        Object.keys(headers).forEach((header) => {
          columns.push({
            title: `${header}`,
            key: header,
            dataIndex: headers[header],
            ellipsis: true,
          });
        });

        settableColumns(columns);
      }
    };

    generateTableHeaders();
  }, [users]);

  const getSelectedUsers = () => {
    const inviteType = selectedUsers.length > 0 ? selectedUsers[0] : {};

    if (Object.keys(inviteType).length === 0) return [];

    if (inviteType.filters) {
      let query: FilterGroupQuery = {
        operation: "and",
        filters: [],
      };

      query.filters = inviteType?.["filters"] ?? [];

      return query;
    }

    const keysToInclude = getAttributeKeysFromCSV(flowInfo.attributes) ?? {};
    return generateInviteUsers(selectedUsers ?? [], keysToInclude);
  };

  const inviteNewUsers = async (instant: boolean = false) => {
    if (loading) return;

    let microFlowName = "";

    if (campaignData) {
      microFlowName = campaignData["micro_flow"] ?? "";

      // if empty micro flow then clear
      microFlowName = microFlowName === "micro_flow_back" ? "" : microFlowName;
    }

    const users = getSelectedUsers();

    let timing: RepeatTiming | number = 0;
    let endTiming: RepeatTiming | undefined =
      endsOn === "Never"
        ? undefined
        : endingDate === ""
        ? undefined
        : generateRepeatTiming(endingDate, startingTime);

    if (instant) {
      timing = 0;
    } else {
      if (repeatType === "Once")
        timing = generateTimestamp(startingDate, startingTime);
      else
        timing = generateRepeatTiming(
          startingDate,
          startingTime,
          repeatType === "Custom" ? customRepeat : repeatType
        );
    }

    setLoading(true);

    try {
      const token = localStorage.getItem('token')

      let result;


      if (selectedChannel === "Whatsapp_Chatbot") {
        result = await unifiedInviteAPI({
          flowId,
          token,
          template: selectedTemplate,
          users: users,
          invite_duplicates: inviteExisting,
          scheduled: timing,
          end_timing: endTiming,
          data: {
            campaign_name: campaignName,
            invited_by: userMail,
          },
          send_templates: true,
          micro_flow: microFlowName,
        });
      } else if (selectedChannel !== "Whatsapp Chatbot" && subChannel === "Email Invitation" ) {
        result = await abcAPI({
          flowId,
          token,
          template: selectedTemplate,
          users: users,
          invite_duplicates: inviteExisting,
          scheduled: timing,
          end_timing: endTiming,
          data: {
            campaign_name: campaignName,
            invited_by: userMail,
          },
          channel: "EmailChat",
          sourcechannel: selectedChannel
        });
      } else if (selectedChannel !== "Whatsapp Chatbot" && subChannel === "Whatsapp Invitation" ) {
        result = await abcAPI({
          flowId,
          token,
          template: selectedTemplate,
          users: users,
          invite_duplicates: inviteExisting,
          scheduled: timing,
          end_timing: endTiming,
          data: {
            campaign_name: campaignName,
            invited_by: userMail,
          },
          channel: "WhatsappChat",
          sourcechannel: selectedChannel// Adjust this based on your API's requirements
        });
      } else if (selectedChannel === "Slack Chatbot") {

        result = await slackInviteAPI({
          flowId,
          token,
          template: selectedTemplate,
          users: users,
          invite_duplicates: inviteExisting,
          scheduled: timing,
          end_timing: endTiming,
          data: {
            campaign_name: campaignName,
            invited_by: userMail,
          },
          send_templates: true,
          micro_flow: microFlowName,
        });
      }


      setLoading(false);

      if (result) {
        removeCachedUsers();
        clearCampaignData();
        navigate(`/dashboard/${flowId}/history/`);
        toastMessage({ description: "invite successful", status: "success" });
      }
    } catch (error) {
      removeCachedUsers();
      clearCampaignData();
      navigate(`/dashboard/${flowId}/history/`);
      toastMessage({ description: "something went wrong", status: "error" });
    }
  };

  return (
    <BaseInviteLayout footer={false}>
      <Stack
        overflowY="scroll"
        w="full"
        spacing={8}
        flex={1}
        px={12}
        pt={12}
        {...props}
      >
        <Box>
          <Heading>Review</Heading>
          <p className="mb-2 text-lg">
            make sure you have got everything right
          </p>
          <Input
            size="md"
            value={campaignName}
            onChange={(event) => setCampaignName(event.currentTarget.value)}
          />
        </Box>
        <SimpleGrid flex={1} gridColumnGap={2} gridTemplateColumns="3fr 7fr">
          <Box>
            <Box
              p={4}
              h={96}
              overflowY="scroll"
              overflowX="scroll"
              style={{ background: `url(${chatImage}) #FAFBFC` }}
              boxShadow="sm"
              borderWidth="thin"
              borderRadius="md"
            >
              <Stack
                my={2}
                borderRadius="md"
                boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.12);"
                bg={"purple.50"}
              >
                <WhatsAppTemplate
                  showTemplateName={false}
                  showButtons={false}
                  showTemplateStatus={false}
                  flowId={flowId}
                  template={cachedTemplate}
                  textProps={{ fontSize: "sm" }}
                />
              </Stack>
              <Stack spacing={1}>
                {cachedTemplate.buttons?.map((button, idx) => (
                  <Button key={`button_${idx}`} size="xs">
                    {button}
                  </Button>
                ))}
              </Stack>
            </Box>
            <Text isTruncated className="align-middle text-center text-sm py-1">
              Template Preview: <b>{cachedTemplate.name}</b>
            </Text>
          </Box>
          <Box overflowX="scroll">
            <TableContainer
              h={96}
              overflowY="scroll"
              overflowX="scroll"
              boxShadow="sm"
              borderWidth="thin"
              borderRadius="md"
            >
              <Table>
                <Thead>
                  <Tr>
                    {tableColumns.map((column) => (
                      <Th>{column.title}</Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {users.map((user, idx) => (
                    <Tr key={`user_record_${idx}`}>
                      {tableColumns.map((column) => (
                        <Td key={`user_record_${idx}_${column.key}`}>
                          {user[column.dataIndex] ?? ""}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
            <Text className="align-middle text-center text-sm py-1 font-bold">
              {users.length} user(s) selected
            </Text>
          </Box>
        </SimpleGrid>
      </Stack>
      <Flex
        w="full"
        direction="row"  // Align children horizontally
        justify="space-between"  // Space out the stacks
        borderTopWidth="thin"
        background="rgba(249,250,251,.6)"
        backdropFilter="blur(32px)"
        p={4}  // Padding around the container
      >
        {/* First Stack aligned to the start */}
        <Stack
          align="start"
          spacing={2}  // Add spacing between text elements
          borderTopWidth="thin"
          background="rgba(249,250,251,.6)"
          backdropFilter="blur(32px)"
          p={2}  // Padding around the stack
        >
          <Text>
            <Text as="span" fontWeight="bold">Selected Channel:</Text> {selectedChannel}
          </Text>
          <Text>
            <Text as="span" fontWeight="bold">Invitation Link:</Text> {subChannel}
          </Text>
        </Stack>

        {/* Second Stack aligned to the end */}
        <Stack
          align="center"
          justify="flex-end"
          direction="row"
          spacing={2}  // Add spacing between checkbox and label
        >
          <Checkbox
            defaultChecked={false}
            onChange={(e) => setInviteExisting(e.target.checked)}
            py={2}
            size="md"  // Adjust size for better readability
          >
            Send to users already existing in the flow
          </Checkbox>
        </Stack>
      </Flex>

      <HStack
        w="full"
        borderTopWidth="thin"
        px={8}
        py={4}
        justify="space-between"
      >
        <Button variant="secondary" onClick={() => props.onBack?.()}>
          Back
        </Button>

        <Button variant="secondary" onClick={() => console.log(`Selected Channel: ${selectedChannel}`)} >
          {selectedChannel || "No Channel Selected"}
        </Button>

        {/* <Box>
          <Popover>
            <PopoverTrigger>
              <Button rightIcon={<ChevronDownIcon />}>
                {selectedOption || "Select an option"}
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                position="fixed"
                top="65%"
                left="45%"
                transform="translate(-65%, -45%)"
                boxShadow="lg"
                zIndex="popover" // Ensures it is on top of other content
              >
                <PopoverArrow />
                <PopoverHeader>Send Options</PopoverHeader>
                <PopoverBody>
                  <Stack spacing={2}>
                    <Button
                      w="100%"
                      onClick={() => {
                        setSelectedOption("Whatsapp");
                      }}
                    >
                      Whatsapp
                    </Button>
                    <Popover>
                      <PopoverTrigger>
                        <Button
                          w="100%"
                          onClick={() => {
                            setSelectedOption("");
                          }}
                        >
                          Chatbot
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent
                        position="fixed"
                        top="80%"
                        left="70%"
                        transform="translate(-80%, -70%)"
                        boxShadow="lg"
                        zIndex="popover"
                      >
                        <PopoverArrow />
                        <Stack spacing={2}>
                          <Button
                            w="100%"
                            onClick={() => {
                              setSelectedOption("EmailChat");
                            }}
                          >
                            EmailChat
                          </Button>
                          <Button
                            w="100%"
                            onClick={() => {
                              setSelectedOption("WhatsappChat");
                            }}
                          >
                            WhatsappChat
                          </Button>
                        </Stack>
                      </PopoverContent>
                    </Popover>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Box> */}

        <ButtonGroup isAttached isDisabled={users.length === 0}>
          <Button
            isLoading={loading}
            askConfirmation
            confirmText="Are you sure the user(s) have consented to be contacted by you?"
            onClick={() => inviteNewUsers(true)}
            disabled={users.length === 0}
          >
            Send Now
          </Button>
          <Button
            leftIcon={<BsClock />}
            onClick={() => onOpen()}
            disabled={users.length === 0}
          >
            Later
          </Button>
        </ButtonGroup>
      </HStack>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Schedule Later</ModalHeader>
          <ModalCloseButton />
          <ModalBody as={Stack} spacing={4}>
            <Box>
              <Text fontWeight="medium" fontSize="md">
                Select Start date
              </Text>
              <Input
                value={startingDate}
                type="date"
                placeholder="When to schedule?"
                onChange={(event) => setStartingDate(event.currentTarget.value)}
              />
            </Box>
            <Box hidden={startingDate === ""}>
              <Text fontWeight="medium" fontSize="md">
                Select Time
              </Text>
              <Input
                value={startingTime}
                type="time"
                placeholder="When to schedule?"
                onChange={(event) => setStartingTime(event.currentTarget.value)}
              />
            </Box>
            <Box hidden={startingTime === ""}>
              <Text fontWeight="medium" fontSize="md">
                Repeats
              </Text>
              <RadioGroup
                value={repeatType}
                onChange={(val) => setRepeatType(val as RepeatType)}
              >
                <Stack direction="row">
                  {["Once", "Daily", "Custom"].map((option, idx) => (
                    <Radio key={idx} value={option}>
                      {option}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </Box>
            <Menu matchWidth>
              <MenuButton
                hidden={repeatType !== "Custom"}
                w="100%"
                as={ChakraButton}
                size="md"
                rightIcon={<ChevronDownIcon />}
              >
                {customRepeat.length === 0 ? "Select Days" : `${customRepeat}`}
              </MenuButton>
              <MenuList className="overflow-y-scroll" maxH={48} boxShadow="lg">
                <MenuOptionGroup
                  type="checkbox"
                  defaultValue={customRepeat}
                  onChange={(selectedDays) =>
                    typeof selectedDays === "string"
                      ? {}
                      : setCustomRepeat(selectedDays)
                  }
                >
                  {WEEKDAYS.map((weekDay, idx) => (
                    <MenuItemOption key={idx} value={weekDay}>
                      {weekDay}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
            <Box hidden={startingTime === ""}>
              <Text fontWeight="medium" fontSize="md">
                Ends
              </Text>
              <RadioGroup
                value={endsOn}
                onChange={(val) => setEndsOn(val as EndsType)}
              >
                <Stack direction="row">
                  {["Never", "Custom"].map((option, idx) => (
                    <Radio key={idx} value={option}>
                      {option}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </Box>
            <Input
              hidden={endsOn !== "Custom"}
              value={endingDate}
              type="date"
              min={Date.now()}
              placeholder="When to end?"
              onChange={(event) => setEndingDate(event.currentTarget.value)}
            />
          </ModalBody>
          <ModalFooter as={Stack} direction="row">
            <Button
              onClick={() => inviteNewUsers()}
              size="sm"
              isLoading={loading}
              disabled={startingTime === ""}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </BaseInviteLayout>
  );
};
