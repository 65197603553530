import React, { useState } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button, MenuProps, useDisclosure } from '@chakra-ui/react';
import StatusTag from "./Status";

interface DropdownOption {
  label: string;
  value: string;
}

interface DropdownProps extends Partial<MenuProps> {
  options: DropdownOption[];
  onSelect: (value: string) => void;
  onStatusClose?: () => void;
  initial: string;

}

const StatusDropdown: React.FC<DropdownProps> = ({ options, onSelect, onStatusClose, initial,  ...menuProps }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOption, setSelectedOption] = useState<DropdownOption>(options[0]);
  const handleSelect = (option: DropdownOption) => {  
    setSelectedOption(option);
    onSelect(option.value);
    onClose()
    
  };
  return (
    <Menu {...menuProps}>
      <MenuButton as={Button}>
      {initial != "" ? initial : selectedOption.label}
      </MenuButton>
      <MenuList>
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => {handleSelect(option)}}>
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default StatusDropdown;