import React, { useState, useEffect } from "react";
import {
  VStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  Input,
  FormErrorMessage,
  Stack,
  ButtonGroup,
  useToast,
  Heading,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Select,
} from "@chakra-ui/react";
import { Formik, Field } from "formik";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import "react-phone-input-2/lib/high-res.css";

import { Button } from "../components/Button";
import { DashboardTemplate } from "../models/property";
import { useStoreActions, useStoreState } from "../hooks/store";
import { ExportIcon } from "../constants/icons";
import { CSVInput } from "../components/Input/Csv";
import { isValidPhoneNumber } from "../utils/countryCodes";
import { generateCSVExport } from "../utils";
import { useUploadFile } from "../mutations/root/useUploadFile";
import { useFeatureFlags } from "../data/flags/useFeatureFlags";
import { DISABLE_INTERNATIONAL_NO_IMPORT } from '../constants';
import { useNavigate } from 'react-router-dom';

type SelectUsersProps = {
  flowId: string;
  template?: DashboardTemplate;
  onNext?: () => void;
  onPrevious?: () => void;
  onBulkImport?: (users: any[]) => void;
  setSelectedChannel?: (value: string) => void;
  selectedChannel?: string;
  subChannel?: string;
  setsubChannel?: (value: string) => void;
};

const UserInviteRequestSchema = Yup.object().shape({
  name: Yup.string().max(1000, 'Too Long!'),
  phone: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Invalid email address').required('Email is required')
});

export const SelectNewUsers = ({
  flowId,
  onBulkImport,
  setSelectedChannel,
  selectedChannel = "Whatsapp Chatbot",
  subChannel,
  setsubChannel,
  ...props
}: SelectUsersProps) => {
  const navigate = useNavigate();
  const invitedUsers = useStoreState((state) => state.newUsers);
  const storeInvitedUsers = useStoreActions((actions) => actions.cacheNewUsers);
  const removeCachedUsers = useStoreActions((actions) => actions.clearNewUsers);
  const storeCampaignData = useStoreActions((actions) => actions.cacheCampaignData);
  const toastMessage = useToast();
  const uploadFileMutation = useUploadFile();

  const [users, setUsers] = useState(invitedUsers ?? []);
  const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState([]);
  const { data: flags } = useFeatureFlags(flowId);

  const [selectedValue, setSelectedValue] = useState(selectedChannel ?? '');

  useEffect(() => {
    console.log('Current selected channel:', selectedChannel);
  }, [selectedChannel]);

  const isFeatureEnabled = (feature) => {
    return (flags?.flags ?? []).includes(feature);
  };

  const uploadCSVFile = async (file) => {
    if (!file) return;

    try {
      let response = await uploadFileMutation.mutateAsync(file);
      if (response) {
        storeCampaignData({
          key: 'csv',
          value: response.url,
        });
      }
    } catch (error) {
      toastMessage({
        status: 'error',
        title: 'Upload Error',
        description: 'Failed to upload CSV file. Please try again.',
        position: 'bottom',
      });
    }
  };

  const storeSingleUser = (name, phone, email) => {
    const user = { Name: name, 'Phone Number': phone, Email: email };

    storeInvitedUsers([user]);
    setUsers([user]);

    toastMessage({
      status: 'success',
      description: `Saved user: ${name}`,
      position: 'bottom',
    });
  };

  const validateCSVData = (data) => {
    const invalidPhoneNumbers = [];
    const validPhoneNumbers = [];
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/; // Simple email validation regex
    const numberRegex = /(?<!\d)(\d{1,3})(\d{3})(\d{2})(\d{1})(\d{4})(?!\d)/;
    data.forEach((user) => {
      const phone = user['Phone Number'];
      const email = user['Email'];

      console.log('ph',phone)
      console.log('em',email)

      if (!emailRegex.test(email) || !numberRegex.test(phone)) {
        console.log('user',user)
        invalidPhoneNumbers.push(user);
      } else {
        validPhoneNumbers.push(user);
      }
    });

    return { invalidPhoneNumbers, validPhoneNumbers };
  };

  const handleChannelChange = (e) => {
    const newValue = e.target.value;
    setSelectedValue(newValue);
    if (setSelectedChannel) {
      setSelectedChannel(newValue);
    }
    if (setsubChannel) {
      setsubChannel(""); // Reset sub-channel when channel changes
    }
  };

  return (
    <Stack justify="center" alignItems="start">
      <Heading className="text-left">Who?</Heading>
      <p className="text-left text-xl">Which users do you want to ping?</p>
      <main className="mt-8 w-full rounded-lg">
        <Tabs colorScheme="pink" variant="enclosed" isLazy>
          <TabList>
            <Tab onClick={() => removeCachedUsers()}>Bulk</Tab>
            <Tab onClick={() => removeCachedUsers()}>Single</Tab>
          </TabList>

          <TabPanels className="w-full">
            <TabPanel as={VStack} className="w-full" px={24} py={6}>
              {flowId == 'csb-cet' ||  flowId == 'csb-cet-v2' ? <Button
                leftIcon={<ExportIcon />}
                variant="tertiary"
                onClick={() => {
                  window.open("/assets/sample_csb.csv", "_blank");
                }}
              >
                Download sample file
              </Button> : <Button
                leftIcon={<ExportIcon />}
                variant="tertiary"
                onClick={() => {
                  window.open("/assets/sample.csv", "_blank");
                }}
              >
                Download sample file
              </Button>}
              <CSVInput
                className="w-full rounded-xl"
                onChange={(file) => uploadCSVFile(file)}
                onParse={(data, clearFile) => {
                  if (data.length === 0) {
                    onBulkImport?.([]);
                    storeInvitedUsers([]);
                    setInvalidPhoneNumbers([]);
                    return setUsers([]);
                  }

                  // if (isFeatureEnabled(DISABLE_INTERNATIONAL_NO_IMPORT)) {
                  //   alert('3')
                  //   onBulkImport?.(data);
                  //   storeInvitedUsers(data);
                  //   return setUsers(data);
                  // }
                  const { invalidPhoneNumbers, validPhoneNumbers } = validateCSVData(data);

                  setInvalidPhoneNumbers(invalidPhoneNumbers);

                  if (validPhoneNumbers.length > 0 && invalidPhoneNumbers.length == 0) {
                    onBulkImport?.(validPhoneNumbers);
                    storeInvitedUsers(validPhoneNumbers);
                    setUsers(validPhoneNumbers);
                  } else {
                    toastMessage({
                      status: 'error',
                      title: 'No valid phone numbers found',
                      description: 'Try including country code in the phone number',
                      position: 'bottom',
                    });
                    onBulkImport?.([]);
                    storeInvitedUsers([]);
                    setUsers([]);
                    clearFile?.();
                  }
                }}
              />
              <Stack spacing={4} w="full">
                <FormControl>
                  <Select
                    placeholder="Select a channel"
                    borderColor="gray.200"
                    backgroundColor="white"
                    value={selectedValue}
                    onChange={handleChannelChange}
                  >
                    <option value="Whatsapp Chatbot">Whatsapp Chatbot</option>
                    <option value="Web Chatbot">Web Chatbot</option>
                    <option value="Webwhatsapp Chatbot">Webwhatsapp Chatbot</option>
                    <option value="Slack Chatbot">Slack Chatbot</option>
                  </Select>
                </FormControl>
                {(selectedValue === "Web Chatbot" || selectedValue === "Webwhatsapp Chatbot") && (
                  <FormControl>
                    <Select
                      placeholder="Select Invitation Link"
                      borderColor="gray.200"
                      backgroundColor="white"
                      value={subChannel}
                      onChange={(e) => setsubChannel?.(e.target.value)}
                    >
                      <option value="Email Invitation">Email Invitation Link</option>
                      <option value="Whatsapp Invitation">Whatsapp Invitation Link</option>
                    </Select>
                  </FormControl>
                )}
              </Stack>
              {/* {invalidPhoneNumbers.length > 0 && (
                <Alert
                  status="error"
                  variant="subtle"
                  flexDirection="row"
                  alignItems="self-start"
                  py={4}
                >
                  <AlertIcon mr={4} />
                  <Stack spacing={1}>
                    <AlertTitle>
                      Invalid phone numbers or emails found in the file
                    </AlertTitle>
                    <AlertDescription>
                      There are {invalidPhoneNumbers.length} record(s)
                      with invalid phone numbers or emails. Please correct them
                      and try again.
                    </AlertDescription>
                    <Button
                      alignSelf="start"
                      variant="danger"
                      action="delete"
                      onClick={() =>
                        generateCSVExport({
                          records: invalidPhoneNumbers,
                          filename: 'invalid_records.csv',
                        })
                      }
                    >
                      Export
                    </Button>
                  </Stack>
                </Alert>
              )} */}
            </TabPanel>
            <TabPanel as={VStack} className="w-full" px={24} py={6}>
              <Formik
                initialValues={{ name: '', phone: '', email: '' }}
                onSubmit={async (values, { resetForm }) => {
                  storeSingleUser(values.name, values.phone, values.email);
                  onBulkImport?.([]);
                }}
                validationSchema={UserInviteRequestSchema}
              >
                {({ handleSubmit, isSubmitting, resetForm, setFieldValue, values }) => (
                  <Stack w="full" spacing={4}>
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <Input
                            {...field}
                            placeholder="Name"
                            maxLength={1000}
                            minLength={5}
                            borderColor="gray.200"
                            backgroundColor="white"
                          />
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="email">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.email && form.touched.email}
                        >
                          <Input
                            {...field}
                            placeholder="Email"
                            type="email"
                            borderColor="gray.200"
                            backgroundColor="white"
                          />
                          <FormErrorMessage>
                            {form.errors.email}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="phone">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.phone && form.touched.phone}
                        >
                          <PhoneInput
                            placeholder="Phone number"
                            country={"in"}
                            inputStyle={{
                              width: '100%',
                              borderColor: 'var(--chakra-colors-gray-200)',
                              backgroundColor: '#F8F9F9',
                              height: 'var(--chakra-sizes-10)',
                            }}
                            value={values.phone}
                            onChange={(value) => {
                              setFieldValue('phone', value);
                            }}
                          />
                          <FormErrorMessage>
                            {form.errors.phone}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Stack spacing={4} w="full">
                      <FormControl>
                        <Select
                          placeholder="Select a channel"
                          borderColor="gray.200"
                          backgroundColor="white"
                          value={selectedValue}
                          onChange={handleChannelChange}
                        >
                          <option value="Whatsapp Chatbot">Whatsapp Chatbot</option>
                          <option value="Web Chatbot">Web Chatbot</option>
                          <option value="Webwhatsapp Chatbot">Webwhatsapp Chatbot</option>
                        </Select>
                      </FormControl>
                      {(selectedValue === "Web Chatbot" || selectedValue === "Webwhatsapp Chatbot") && (
                        <FormControl>
                          <Select
                            placeholder="Select Invitation Link"
                            borderColor="gray.200"
                            backgroundColor="white"
                            value={subChannel}
                            onChange={(e) => setsubChannel?.(e.target.value)}
                          >
                            <option value="Email Invitation">Email Invitation Link</option>
                            <option value="Whatsapp Invitation">Whatsapp Invitation Link</option>
                          </Select>
                        </FormControl>
                      )}
                    </Stack>
                    <ButtonGroup>
                      <Button
                        onClick={() => {
                          storeInvitedUsers(users);
                          handleSubmit();
                        }}
                        isDisabled={isSubmitting}
                      >
                        Save
                      </Button>
                      <Button onClick={() => resetForm()} variant="tertiary">
                        Cancel
                      </Button>
                    </ButtonGroup>
                  </Stack>
                )}
              </Formik>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </main>
    </Stack>
  );
};
