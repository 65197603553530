import axios from 'axios'
import config from '../config'

const apiRoot = 'v2/auth'
const adminapiRoot = 'api/auth'

interface UserLoginRequest {
    email: string,
    password: string,
    redirect: boolean
}

export const loginUser = async (
    request: UserLoginRequest
) => {
    const response = await axios.post(`${config.url}/${apiRoot}/login`, {
        email: request.email,
        password: request.password,
        redirect: request.redirect
    }, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    return response.data
}

export const adminloginUser = async (
    request: UserLoginRequest
) => {
    const response = await axios.post(`${config.admin_url}/${adminapiRoot}/login`, {
        username: request.email,
        password: request.password,
    }, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    localStorage.setItem("admin_token", response.data.token)
    return response.data
}





