import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  VStack,
  Container,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Spacer,
  Spinner,
  Text,
  Grid,
  GridItem,
  IconButton,
} from "@chakra-ui/react";
import "react-phone-input-2/lib/high-res.css";
import ConfigureSource from "./GoLiveSteps/ConfigureSource";
import { BotRecords, ExportStep, goLiveData } from "../api/go_live";
import SetDashboardData from "./GoLiveSteps/SetDashboardData";
import Assignment from "./GoLiveSteps/Assign";
import { Button } from "../components/Button";
import { BackIcon } from "../constants/icons";
import { FiChevronLeft } from "react-icons/fi";

interface GoLiveStepsProps {
  record: BotRecords;
  onBack: () => void;
}

// Define all config interfaces in the parent component
interface SourceConfig {
  botName: string;
  sourceType: string;
  countryCode: string;
  source_number: string;
  flowId: string;
  orgNames: string[];
  userIds: string[];
  template_userid?: string;
  template_password?: string;
  session_userid?: string;
  session_password?: string;
  gupshup_userid?: string;
  gupshup_password?: string;
  url?: string;
  key?: string;
  namespace?: string;
  webhook_token?: string;
  auth_token?: string;
  source_id?: string;
}

interface SelectedUser {
  userId: string;
  username: string;
}

interface AssignmentConfig {
  selectedOrganization: string;
  organizationId: number;
  selectedUsers: SelectedUser[]; // Change to an array of SelectedUser objects
  newUser?: {
    firstName: string;
    lastName: string;
    email: string;
  };
}
interface DashboardConfig {
  botName: string;
  botType: string;
  company: string;
  role: string;
  info: string;
  isVideoInterviewEnabled: boolean;
  isAssesshubTestEnabled: boolean;
  exportFields: ExportStep[];
  summaryFields: ExportStep[];
}

const steps = [
  { title: "Configure Source", description: "Set up the bot source" },
  { title: "Assignment", description: "Link bot to organizations" },
  { title: "Set Dashboard Data", description: "Configure dashboard information" },
];

const GoLiveSteps: React.FC<GoLiveStepsProps> = ({ record, onBack }) => {
  const navigate = useNavigate();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [sourceOption, setSourceOption] = useState<"existing" | "new">("existing");

  const [sourceConfig, setSourceConfig] = useState<SourceConfig>({
    botName: record.data["Bot Name"] || "",
    sourceType: record.data["Source Type"] || "",
    countryCode: record.data["Country Code"] || "",
    source_number: record.data["Business Number"] || "",
    flowId: record.data["Flow ID"] || "",
    orgNames: record.data["Organization Name"] || "",
    userIds: record.data["User IDs"] || [],
    template_userid: record.data["Template User ID"] || "",
    template_password: record.data["Template Password"] || "",
    session_userid: record.data["Session User ID"] || "",
    session_password: record.data["Session Password"] || "",
    gupshup_userid: record.data["Gupshup User ID"] || "",
    gupshup_password: record.data["Gupshup Password"] || "",
    url: record.data["URL"] || "",
    key: record.data["Key"] || "",
    namespace: record.data["Namespace"] || "",
    webhook_token: record.data["Webhook Token"] || "",
    auth_token: record.data["Auth Token"] || "",
    source_id: "",
  });

  const [assignmentConfig, setAssignmentConfig] = useState<AssignmentConfig>({
    selectedOrganization: "",
    organizationId: null,
    selectedUsers: [],
    newUser: undefined,
  });

  const [dashboardConfig, setDashboardConfig] = useState<DashboardConfig>({
    botName: record.data["Bot Name"] || "",
    botType: "",
    company: "",
    role: "",
    info: "",
    isVideoInterviewEnabled: false,
    isAssesshubTestEnabled: false,
    exportFields: [],
    summaryFields: [],
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const processFields = (fields: ExportStep[]) => {
    return fields.reduce((acc, field) => {
      if (field.isSelected && field.label && field.answer_field) {
        acc[field.label] = field.answer_field;
      }
      return acc;
    }, {});
  };

  const handleGoLive = async () => {
    setIsLoading(true);
    // Combine data from the steps
    const combinedData = {
      golive_data: [
        {
          source: {
            source_name: sourceConfig.botName,
            source_type: sourceConfig.sourceType,
            source_number: sourceConfig.source_number,
            credentials: {},
            flow_id: record.data["Flow Id"] || "",
            isExisting: sourceOption === "existing", // Add isExisting field
            source_id: sourceConfig.source_id, // Add source_id field
          },
          flow_dashboard_data: {
            bot_id: record.data["Flow Id"] || "",
            bot_name: dashboardConfig.botName,
            bot_type: dashboardConfig.botType,
            bot_source: sourceConfig.source_id || record.data["Flow Id"] || "",
            replier_version: "v2",
            jmespath: true,
            values: {
              company: {
                info: "Company name",
                value: dashboardConfig.company,
              },
              role: {
                info: "Role name",
                value: dashboardConfig.role,
              },
              info: {
                info: "Additional Info",
                value: dashboardConfig.info,
              },
            },
            template: {},
            dashboard: {
              detailed: {},
              export: processFields(dashboardConfig.exportFields),
              summary: processFields(dashboardConfig.summaryFields),
            },
          },
          assignment: {
            master_orgId: 2,
            master_userId: 4,
            organization_id: assignmentConfig.organizationId,
            organizations_name: assignmentConfig.selectedOrganization,
            flow_id: record.data["Flow Id"] || "",
            users: assignmentConfig.selectedUsers.map((user) => ({
              username: user.username,
              user_id: user.userId,
            })),
          },
        },
      ],
    };

    // Set credentials based on source type
    if (sourceConfig.sourceType === "gupshup") {
      combinedData.golive_data[0].source.credentials = {
        template_userid: sourceConfig.template_userid,
        template_password: sourceConfig.template_password,
        session_userid: sourceConfig.session_userid,
        session_password: sourceConfig.session_password,
        gupshup_userid: sourceConfig.gupshup_userid,
        gupshup_password: sourceConfig.gupshup_password,
      };
    } else if (sourceConfig.sourceType === "dialog-360") {
      combinedData.golive_data[0].source.credentials = {
        url: sourceConfig.url,
        key: sourceConfig.key,
        namespace: sourceConfig.namespace,
        webhook_token: sourceConfig.webhook_token,
      };
    } else if (sourceConfig.sourceType === "valueFirst") {
      combinedData.golive_data[0].source.credentials = {
        auth_token: sourceConfig.auth_token,
      };
    }

    console.log("combined data --> ", combinedData);
    try {
      const response = await goLiveData(combinedData);
      console.log("Go Live response:", response);
      onBack();
      // Handle success (e.g., navigate to another page, show success message, etc.)
    } catch (error) {
      console.error("Go Live error:", error);
      // Handle error (e.g., show error message, retry logic, etc.)
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ConfigureSource
            sourceConfig={sourceConfig}
            setSourceConfig={setSourceConfig}
            sourceOption={sourceOption} // Pass sourceOption
            setSourceOption={setSourceOption} // Pass setSourceOption
          />
        );

      case 1:
        return <Assignment assignmentConfig={assignmentConfig} setAssignmentConfig={setAssignmentConfig} />;
      case 2:
        return <SetDashboardData record={record} dashboardConfig={dashboardConfig} setDashboardConfig={setDashboardConfig} />;
      default:
        return null;
    }
  };

  return (
    <VStack spacing={0} bg="white" w="full" h="100vh">
      <Box px={0} py={2} borderBottom="1px solid" shadow="sm" w="full">
        <Container maxW="container.xl" px={0}>
          <Flex align="center">
            <Box pl={4}>
              <IconButton aria-label="Back" icon={<BackIcon />} onClick={onBack} size="sm" />
            </Box>
            <Box flex="1">
              <Container maxW="container.lg" px={0}>
                <Grid templateColumns="1fr auto" gap={4} alignItems="center">
                  <GridItem>
                    <Stepper colorScheme="pink" size="sm" index={activeStep}>
                      {steps.map((step, index) => (
                        <Step key={index}>
                          <StepIndicator>
                            <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                          </StepIndicator>
                          <Box flexShrink={0}>
                            <StepTitle>{step.title}</StepTitle>
                          </Box>
                          <StepSeparator />
                        </Step>
                      ))}
                    </Stepper>
                  </GridItem>
                </Grid>
              </Container>
            </Box>
          </Flex>
        </Container>
      </Box>
      {isLoading && (
        <Box
          position="fixed"
          top="0"
          left="0"
          w="100vw"
          h="100vh"
          bg="rgba(0, 0, 0, 0.5)"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex="overlay"
        >
          <VStack spacing={4} align="center">
            <Spinner size="xl" />
            <Text color="white" fontSize="lg">
              <b>Processing...</b>
            </Text>
          </VStack>
        </Box>
      )}
      <Box w="full" py={4} flex="1" display="flex" flexDirection="column">
        <Container p={0} maxW="container.lg" h="calc(100vh - 80px)" display="flex" flexDirection="column">
          <Card h="full" overflow="hidden" display="flex" flexDirection="column" boxShadow="md" borderRadius="md">
            <CardHeader borderBottom="1px solid" borderColor="gray.200" p={4}>
              <Heading as="h2" size="lg">
                {record.data["Bot Name"]} Go-Live Process
              </Heading>
            </CardHeader>
            <CardBody overflowY="auto" flex="1" p={4}>
              {renderStepContent(activeStep)}
            </CardBody>
            <CardFooter borderTop="1px solid" borderColor="gray.200" p={4}>
              <Flex w="full" justify="space-between">
                <Button onClick={handlePrevious} isDisabled={activeStep === 0}>
                  Previous
                </Button>
                {activeStep === steps.length - 1 ? (
                  <Button onClick={handleGoLive} colorScheme="green">
                    Go Live
                  </Button>
                ) : (
                  <Button onClick={handleNext}>Next</Button>
                )}
              </Flex>
            </CardFooter>
          </Card>
        </Container>
      </Box>
    </VStack>
  );
};
export default GoLiveSteps;
