import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Container,
} from "@chakra-ui/react";
import {
  SelectUsers,
  SelectTemplate,
  EditTemplate,
  ReviewInvite,
  SelectConversation,
} from "./";
import { useStoreState } from "../../../hooks/store";
import { inviteSteps } from "../../../constants";

export const InviteHome = () => {
  const { id, botId } = useParams();
  const flowId = botId ?? id;
  const [selectedChannel, setSelectedChannel] = useState("Whatsapp_Chatbot"); // Set default value here
  const [subChannel, setsubChannel] = useState("whatsappInvitation");
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 5,
  });

  const campaignData = useStoreState((state) => state.campaignData);

  useEffect(() => {
    const microFlowName = campaignData["micro_flow"] ?? "";

    if (microFlowName) {
      // blank micro flow should redirect back to the conversation selection
      if (microFlowName === "micro_flow_back") {
        return setActiveStep(3);
      }
      setActiveStep(4);
    }
  }, []);

  return (
    <Stack spacing={0} className="bg-white w-full h-screen overflow-y-scroll">
      <Box className="px-2 py-5 border-b shadow-sm">
        <Container maxW="container.lg">
          <Stepper colorScheme="pink" size="sm" index={activeStep}>
            {inviteSteps.map((item, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>

                <Box flexShrink="0">
                  <StepTitle>{item.title}</StepTitle>
                </Box>

                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </Container>
      </Box>
      <Box className="min-w-full py-6 flex-1 overflow-hidden">
        <Container
          p={0}
          className="border shadow-md rounded-md h-full relative"
          maxW="container.lg"
        >
          {/* <Box className="absolute flex flex-row space-x-1 items-center justify-center bg-purple-50 top-0 left-0 right-0 p-2 rounded-t-md">
            <InfoIcon className="text-purple-700" />
            <p className="text-center text-purple-600 text-sm">
              We support international phone numbers only, so{" "}
              <b>country codes are mandatory</b>
            </p>
          </Box> */}
          {activeStep == 0 ? (
            <SelectUsers flowId={flowId} selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel} setsubChannel={setsubChannel} onNext={() => setActiveStep(1)} />
          ) : activeStep == 1 ? (
            <SelectTemplate
              flowId={flowId}
              onNext={() => setActiveStep(2)}
              onBack={() => setActiveStep(0)}
            />
          ) : activeStep == 2 ? (
            <EditTemplate
              flowId={flowId}
              navigation={true}
              onNext={() => setActiveStep(3)}
              onBack={() => setActiveStep(1)}
            />
          ) : activeStep == 3 ? (
            <SelectConversation
              flowId={flowId}
              onNext={() => setActiveStep(4)}
              onBack={() => setActiveStep(2)}
            />
          ) : (
            <ReviewInvite flowId={flowId} selectedChannel={selectedChannel} subChannel={subChannel} onBack={() => setActiveStep(3)} />
          )}
        </Container>
      </Box>
    </Stack>
  );
};
