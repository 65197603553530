import users from "../img/nav/users.svg";
import conversations from "../img/nav/conversations.svg";
import invite from "../img/nav/invite.svg";
import analytics from "../img/nav/analytics.svg";
import history from "../img/nav/history.svg";
import facts from "../img/nav/facts.svg";
import add from "../img/nav/add.svg";
import support from "../img/nav/support.svg";
import settings from "../img/nav/settings.svg";
import resume from "../img/nav/resume.svg";

type PageRoute = {
  to: string;
  title: string;
  image: any;
  isExternal?: boolean;
};
export const appRoutes: PageRoute[] = [
  {
    to: "/",
    title: "Users",
    image: users,
  },
  {
    to: "/users/new",
    title: "Invite",
    image: invite,
  },
  {
    to: "/history",
    title: "History",
    image: history,
  },
  {
    to: "/knowledge",
    title: "Facts",
    image: facts,
  },
  {
    to: "/support",
    title: "Support",
    image: support,
  },
  {
    to: "/new",
    title: "Add New",
    image: add,
  },
  {
    to: "/conversations",
    title: "Conversations",
    image: conversations,
  },
  {
    to: "/analytics",
    title: "Analytics",
    image: analytics,
  },
  {
    to: "/settings",
    title: "Settings",
    image: settings,
  },
  {
    to: "https://resumeparser.assesshub.com/login",
    title: "Resume Parser",
    image: resume,
    isExternal: true
  }
  // {
  //   to: "/playground",
  //   title: "Playground",
  //   image: automation,
  // },
];
