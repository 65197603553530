import React, { useState } from "react";
import { Box, Heading, Text, HStack, Stack } from "@chakra-ui/react";
import { BaseInviteLayout, InviteViewProps } from "./BaseLayout";
import { SelectNewUsers } from "../../../views/SelectNewUsers";
import { useStoreState } from "../../../hooks/store";
import { SelectExistingUsers } from "../../../views/SelectExistingUsers";
import { colorSystem } from "../../../theme";
import { useFlowInfo } from "../../../hooks/useFlowInfo";
import { useMessageTemplates } from "../../../data/flow/useMessageTemplates";

export const SelectUsers = ({ flowId, setSelectedChannel,setsubChannel,...props }: InviteViewProps) => {
  const [userType, setUserType] = useState<"new" | "existing">("new");
  const [page, setPage] = useState<"userType" | "userSelection">("userType");

  const selectedUsers = useStoreState((state) => state.newUsers);

  // prefetch flow info from API
  const flowInfo = useFlowInfo(flowId, true);

  // prefetch templates from API
  const { data } = useMessageTemplates({
    flowId,
  });

  const handleNextPage = () => {
    if (page === "userSelection") {
      return props.onNext?.();
    }

    return setPage("userSelection");
  };

  const handlePreviousPage = () => {
    if (page === "userSelection") {
      return setPage("userType");
    }
  };

  return (
    <BaseInviteLayout
      nextButton={{
        isDisabled: page === "userType" ? false : selectedUsers.length === 0,
        onClick: () => handleNextPage(),
      }}
      backButton={{
        visibility: page === "userType" ? "hidden" : "visible",
        onClick: () => handlePreviousPage(),
      }}
    >
      {page === "userSelection" ? (
        <Stack>
          {userType === "new" ? (
            <SelectNewUsers flowId={flowId} setSelectedChannel={setSelectedChannel} setsubChannel={setsubChannel}/>
          ) : (
            <SelectExistingUsers flowId={flowId} />
          )}
        </Stack>
      ) : (
        <Stack>
          <Heading>who?</Heading>
          <Text fontSize="lg">which users do you want to ping</Text>
          <HStack spacing={12} className="pt-12 justify-center">
            <Box
              border={
                userType === "new"
                  ? `2px solid ${colorSystem["textPurple"]}`
                  : "none"
              }
              className="rounded-xl cursor-pointer w-4/5 h-48 flex flex-col shadow-lg"
              onClick={() => setUserType("new")}

            >
              <Box className="flex justify-center items-center text-center h-[55%] rounded-t-xl text-6xl bg-white border border-b-0 border-gray-200">
                🤩
              </Box>
              <Box
                className="text-left p-4 h-[45%] rounded-b-xl"
                background="white"
                color="black"
                border="1px"
                borderColor="gray.200"
              >
                <Heading size="md">New</Heading>
                <Text className="text-slate-600">A fresh start</Text>
              </Box>
            </Box>
            <Box
              border={
                userType === "existing"
                  ? `2px solid ${colorSystem["textPurple"]}`
                  : "none"
              }
              className="rounded-xl cursor-pointer w-4/5 h-48 flex flex-col shadow-lg"
              onClick={() => setUserType("existing")}
            >
              <Box className="flex justify-center items-center text-center h-[55%] rounded-t-xl text-6xl bg-white border border-b-0 border-gray-200">
                👥
              </Box>
              <Box
                className="text-left p-4 h-[45%] rounded-b-xl"
                background="white"
                color="black"
                border="1px"
                borderColor="gray.200"
              >
                <Heading size="md">Existing</Heading>
                <Text className="text-slate-600">already contacted</Text>
              </Box>
            </Box>
          </HStack>
        </Stack>
      )}
    </BaseInviteLayout>
  );
};
