import React from 'react'
import RobotImage from '../../img/robot.png'

const FullPageLoader = () => {
    return (
        <div>
            <div className="relative flex justify-center items-center min-h-screen">
                <div className="absolute animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-indigo-500"></div>
                <img src={RobotImage} className="rounded-full h-28 w-28 p-2" />
            </div>
        </div>
    )
}

export default FullPageLoader