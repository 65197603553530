import React, { forwardRef, useEffect, useState, ReactNode } from "react";
import { Stack, Button } from "@chakra-ui/react";
import { TextField } from "./TextField";

export interface DashboardNoteProps {
  allowEditing?: boolean;
  note: string
  onClose?: () => void;
  onChange?: (note: string) => void;
  children?: ReactNode;
}

export const DashboardNote = forwardRef<HTMLDivElement, DashboardNoteProps>(
  (
    {
      allowEditing = false,
      note = "",
      onClose,
      onChange,
      children 
    },
    ref
  ) => {
    const [text, setText] = useState("");
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
      setText(note); 
      if (allowEditing) {
        setIsEditing(true)

      }
    }, [note]);

    const handleAddNote = () => {
      const newNoteKey = `note${Object.keys(note).length + 1}`;
      const updatedNotes = text
      if (onChange) onChange(updatedNotes);
      setText(""); 
    };

    return (
      <div ref={ref}>
        {isEditing &&<Stack spacing={4}>
          
            <TextField
              size="sm"
              minH={24}
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="add a note"
            />
         
            <Stack direction="row-reverse" spacing={2}>
              {text.length === 0 ? <Button
                colorScheme="pink"
                isDisabled={text === ""}
                size="sm"
                onClick={() => {
                  setIsEditing(true);
                  handleAddNote()
                }}
              >
                Add
              </Button> : <Button
                colorScheme="pink"
                isDisabled={text === ""}
                size="sm"
                onClick={() => {
                  setIsEditing(true);
                  handleAddNote()
                }}
              >
                Edit
              </Button>}
              <Button
                size="sm"
                onClick={() => {
                  onClose?.();
                }}
              >
                Close
              </Button>
            </Stack>
          
        </Stack>}
        {children} 
      </div>
    );
  }
);
