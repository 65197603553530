import React from "react";
import { VStack, Box, HStack, BoxProps } from "@chakra-ui/react";
import { Button, WHButtonProps } from "../../../components/Button";

export interface InviteViewProps {
  flowId: string;
  navigation?: boolean;
  setSelectedChannel?:  any;
  selectedChannel?:  any;
  subChannel?: any;
  setsubChannel?: any;
  // setSelectedChannel?: (value: string) => void;
  // selectedChannel?: string;
  // subChannel?: string;
  // setsubChannel?: (value: string) => void;
  onBack?: () => void;
  onNext?: () => void;

}

interface BaseLayoutProps extends BoxProps {
  footer?: boolean;
  helper?: React.ReactNode;
  backButton?: WHButtonProps;
  nextButton?: WHButtonProps;
}

export const BaseInviteLayout = ({
  footer = true,
  backButton,
  nextButton,
  children,
  ...props
}: BaseLayoutProps) => {
  return footer ? (
    <VStack spacing={0} h="full">
      <Box w="full" flex={1} overflowY="scroll" p={12} {...props}>
        {children}
      </Box>
      {props.helper && (
        <VStack
          w="full"
          align="center"
          borderTopWidth="thin"
          background="rgba(249,250,251,.6)"
          backdropFilter="blur(32px)"
        >
          {props.helper}
        </VStack>
      )}
      <HStack
        w="full"
        borderTopWidth="thin"
        px={8}
        py={4}
        justify="space-between"
      >
        <Button variant="tertiary" {...backButton}>
          Back
        </Button>
        <Button {...nextButton}>{props.title ?? "Next"}</Button>
      </HStack>
    </VStack>
  ) : (
    <VStack spacing={0} h="full">
      {children}
    </VStack>
  );
};
